import './style.css';
import Alpine from 'alpinejs'
import anchor from '@alpinejs/anchor'
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'

Alpine.plugin(anchor)
Alpine.plugin(collapse)
Alpine.plugin(intersect)

window.Alpine = Alpine

Alpine.start()

if (process.env.NODE_ENV !== 'production') {
    console.log('Development mode!');
}
